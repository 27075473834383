import React, { createContext, useReducer } from 'react';
import reducer from './reducers';
import locales from '../config/locales';

export const Store = createContext(null);

const initialState = {
  isMobile: false,
  navOpen: false,
  isHomepage: true,
  showSummary: false,
  makeSelection: false,
  city: null,
  hours: 3,
  timeOfDay: 'morning',
  cities: {},
  citiesIds: [],
  locales: locales
};

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return (
    <Store.Provider value={value}>
      {props.children}
    </Store.Provider>
  );
}