import React, { createContext } from 'react';

const LocaleContext = createContext();

function Locale({ children, pageContext: { locale } }) {
  return (
    <LocaleContext.Provider value={{ locale }}>
      <div className="global-wrapper">
        {children}
      </div>
    </LocaleContext.Provider>
  );
}

export { Locale, LocaleContext };