import {
  TOGGLE_MAIN_MENU,
  SET_CITIES,
  SET_HOURS,
  SET_TIME_OF_DAY,
  SET_CITY,
  SET_SELECTION,
  MAKE_SELECTION,
  SET_HOMEPAGE,
  SET_IS_MOBILE
} from '../actions/appActions';


export default function reducer(state, action) {
  console.log(action);
  switch(action.type) {
    case TOGGLE_MAIN_MENU:
      return {
        ...state,
        navOpen: !state.navOpen
      }
    case SET_CITIES:
      return {
        ...state,
        cities: action.cities,
        citiesIds: action.citiesIds
      }
    case SET_HOURS:
      return {
        ...state,
        hours: action.hours
      }
    case SET_TIME_OF_DAY:
      return {
        ...state,
        timeOfDay: action.timeOfDay
      }
    case SET_CITY:
      return {
        ...state,
        city: action.city
      }
    case SET_SELECTION:
      return {
        ...state,
        city: action.city,
        hours: action.hours,
        timeOfDay: action.timeOfDay
      }
    case MAKE_SELECTION:
      return {
        ...state,
        makeSelection: action.makeSelection
      }
    case SET_HOMEPAGE:
      return {
        ...state,
        isHomepage: action.isHomepage
      }
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.isMobile
      }
    default:
      return state;
  }
}