module.exports = {
  default: {
    default: true,
    path: `en`,
    locale: `en`,
    siteLanguage: `English`
  },
  en: {
    path: `en`,
    locale: `en`,
    siteLanguage: `English`
  },
  fr: {
    path: `fr`,
    locale: `fr`,
    siteLanguage: `Français`
  },
  de: {
    path: `de`,
    locale: `de`,
    siteLanguage: `Deutsch`
  },
};