import React from 'react';
import { Locale } from './src/components/Locale';

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
function wrapPageElement({ element, props }) {
  return (
    <Locale {...props}>{element}</Locale>
  );
}

export default wrapPageElement;