export const TOGGLE_MAIN_MENU = 'TOGGLE_MAIN_MENU';
export const SET_CITIES = 'SET_CITIES';
export const SET_HOURS = 'SET_HOURS';
export const SET_TIME_OF_DAY = 'SET_TIME_OF_DAY';
export const SET_CITY = 'SET_CITY';
export const SET_SELECTION = 'SET_SELECITON';
export const MAKE_SELECTION = 'MAKE_SELECTION';
export const SET_HOMEPAGE = 'SET_HOMEPAGE';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';

export function toggleMainMenu() {
  return { type: TOGGLE_MAIN_MENU };
}

export function setCities(cities, citiesIds) {
  return {
    type: SET_CITIES,
    cities,
    citiesIds
  }
}

export function setHours(hours) {
  return {
    type: SET_HOURS,
    hours
  }
}

export function setTimeOfDay(timeOfDay) {
  return {
    type: SET_TIME_OF_DAY,
    timeOfDay
  }
}

export function setCity(cityId) {
  return {
    type: SET_CITY,
    city: cityId
  }
}

/**
 * 
 * @param {number} cityId 
 * @param {number} hours 
 * @param {string} timeOfDay 
 */
export function setSelection(cityId, hours, timeOfDay) {
  return {
    type: SET_SELECTION,
    city: cityId,
    hours,
    timeOfDay
  }
}

export function setMakeSelection(bool) {
  return {
    type: MAKE_SELECTION,
    makeSelection: bool
  }
}

export function setHomepage(bool) {
  return {
    type: SET_HOMEPAGE,
    isHomepage: bool
  }
}

export function setIsMobile(bool) {
  return {
    type: SET_IS_MOBILE,
    isMobile: bool
  }
}